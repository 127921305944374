import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Card = styled.div`
  width: 1024px;
  opacity: 1;
  border-radius: 20px;
  padding: 34px;
  margin: 0 auto;
  
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;

    .title {
      position: relative;
      font-size: 42px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: normal;
      margin-top: 20px;

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(100%);
        content: attr(data-subtitle);
        font-size: 24px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
      }
    }

    .img {
      width: 207px;
      height: 153px;
    }
  }
  
  .content {
    opacity: 1;
    font-size: 20px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    text-align: left;
    color: #666666;
    line-height: 1.5;
    padding-top: 30px;
  }

  .bold {
    font-weight: 600;
  }
  
  .indent {
    text-indent: 36px;
  }
`


const About = () => (
  <Layout>
    <SEO title="关于我们" />

    <Card>
      <div className="banner">
        <div className="title" data-subtitle="ABOUT US">公司介绍</div>
        {/*<img className="img" src={require('../images/speak.svg').default}/>*/}
      </div>
      <div className="content">
        <p className="indent">宠物宝是上海栖凤网络科技有限公司旗下的一款在线宠物交易平台，公司总部位于上海，成立于2018年。 宠物宝平台包括苹果app、安卓app、微信小程序、抖音小程序、百度小程序、pc网站。其平台覆盖全面，技术实力强劲，服务体验完美。涵盖狗狗、猫咪、异宠等数百种宠物品类。志在为中国宠物买家、卖家提供专业的一站式购宠、售宠、养宠服务平台。</p>
        <p className="bold indent">平台面向全国卖家开放入驻：</p>
        <p>在线通过资料审核认证并缴纳保证金后，即可免费开通店铺，在线发布，无中间环节，买卖双方直接对接，交易过程简单高效。优质商家将获得平台更多扶持政策，让您足不出户，面向全国销售您的萌宠！</p>
        <p className="bold indent">平台为买家提供五大安心服务：</p>
        <p className="bold">一、担保交易</p>
        <p className="indent">请买家务必使用平台担保支付交易，商家收到订单后确认发货，发货后10天内买家资金由平台托管，中途出现交易纠纷可有效维护买家权益，避免卖家资金到手后推卸责任，出现纠纷经平台认定为卖家责任的，平台可向买家退回购宠资金。</p>
        <p className="bold">二、10天保障</p>
        <p className="indent">10天健康保障期内，如期间出现纠纷或宠物狗出现 犬瘟细小、传染性肝炎、犬冠状病毒；宠物猫出现猫 瘟、猫艾滋、猫鼻支。提供正规宠物诊所的医学报告， 经认定属实后，将获得最高全额报销。</p>
        <p className="bold">三、先行赔付</p>
        <p className="indent">保障期内商家拒不配合的，联系不上商家的，平台可介入仲裁，若查证属实认定为非买家责任后，平台可先行垫付赔偿资金。让买家的声音能得到及时反馈，权益能得到及时保障！</p>
        <p className="bold">四、商家严审</p>
        <p>
          <div className="indent">1. 平台接入支付宝蚂蚁信用和人脸识别认证，保障商家身份真实，信誉可靠。</div>
          <div className="indent">2. 平台通过实地考察或在线验证商家营业执照资质，商家资质更加可靠。</div>
          <div className="indent">3. 商家入驻需要缴纳保证金，作为交易信誉担保。为买家提供保障基石。</div>
        </p>
        <p className="bold">五、100%实拍</p>
        <p>
          <div className="indent">1. 卖家发布宠物需要签订100%实拍协议，违规将得到平台处罚，平台把好协议关。</div>
          <div className="indent">2. 平台通过人工审核，虚假图片、虚假视频将被删除，同时对商家进行警告或封禁处罚。人工把好审核关。</div>
          <div className="indent">3. 平台再次通过大数据和机器智能审核，对盗图进行自动识别删除。系统把好识别关。</div>
          <div className="indent">4. 平台开放举报窗口，用户可对不规范不诚信商铺进行举报经平台审核属实后，将对商品下架并对商家做相应处罚。用户把好举报关。</div>
        </p>
        <p className="indent">
          我们心愿与全国宠物买家、卖家一道，为规范中国宠物行业做出点滴贡献，并以此为我们持续前进的动力和目标！<br/>
        </p>
        <p>
          联系我们<br/>
          官方公众号：buyonepet2021<br/>
          官方客服：400-018-2021（工作日9:00-18:00）
        </p>
      </div>
    </Card>
  </Layout>
)

export default About
